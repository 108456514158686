$(document).ready(function(){

    $('form').on('change', '.select_code_site', function(){
        var value = $(this).val();
        $(this).parent().find('.code-site').val(value);
    });

    updateCodeSiteToUsers();

    function updateCodeSiteToUsers() {
        $('.societes-tab-content .tab-pane').each(function(i, societe){

            var options = '<option></option>';

            $(societe).find('.sites .col-sm-12').each(function(i, site){
                options += '<option value="'+ $(site).find('.code-site').val() +'">'+ $(site).find('.site-denomination').val() +'</option>';
            });

            $(societe).find('.users .col-sm-12').each(function(i, user){
                $(user).find('.select_code_site').html(options);

                var value = $(user).find('.code-site').val();
                $(user).find('.select_code_site').val(value);

                if (!$(user).find('.select_code_site').val())
                    value = $(user).find('.code-site').val('');
            });
        });
    }

    $("form").on('keyup', ".sites .code-site", function(){
        updateCodeSiteToUsers();
    });

    function generateSiteCode(name) {
        return name
        .toUpperCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'');
    }

    $("form").on('keyup', "input.to_uppercase", function(){
        var start = this.selectionStart,
        end = this.selectionEnd;

        $(this).val( $(this).val().toUpperCase() );

        this.setSelectionRange(start, end);
    });

    $("form").on('keyup', "input.phone_number", function(){
        var $this = $(this);
        if ((($this.val().length+1) % 3)==0){
            if ($this.val().length < 14)
                $this.val($this.val() + " ");
        }
    });

    $("#form_deployment").validationEngine();

    $('.societes-tab-nav li a').on('click', function(){
        var error = $("#form_deployment").validationEngine('validate');
        return error;
    });

    function valid_societes_name() {

        var result = true;

        var societes = [];

        $('.societes-tab-content').find('.societe-name').each(function(i, e){

            if (typeof societes[$(e).val()] != 'undefined') {
                result = false;
                return false;
            }

            societes[$(e).val()] = $(e).val();
        });

        return result;
    }

    function valid_codesite_sites() {

        var result = true;

        var codes = [];

        $('.societes-tab-content').find('.sites .code-site').each(function(i, e){

            if (typeof codes[$(e).val()] != 'undefined') {
                result = false;
                return false;
            }

            codes[$(e).val()] = $(e).val();
        });

        return result;
    }

    function valid_codesite_users() {

        var result = true;

        $('.societes-tab-content .tab-pane').each(function(i, e){
            var codes = [];

            $(e).find('.sites').find('.code-site').each(function(i, e){
                codes[$(e).val()] = $(e).val();
            });

            $(e).find('.users').find('.code-site').each(function(i, e){
                if (typeof codes[$(e).val()] == 'undefined') {
                    result = false;
                    return false;
                }
            });

            if (result == false)
                return false;
        });

        return result;
    }

    function valid_login() {
        var logins = [];
        var result = true;

        $('.compte-login').each(function(i, e){
            var login = $(e).val();

            if (typeof logins[login] != 'undefined') {
                result = false;
                return false;
            }

            logins[login] = login;
        });

        return result;
    }

    function valid_lead_societe() {

        var result = true;

        $('.societes-tab-content .tab-pane').each(function(i, e){
            var count = 0;

            $(e).find('.compte-lead').each(function(i, e){
                if ($(e).val() == 1) {
                    count++;
                }
            });

            if (count > 1) {
                result = false;
                return false;
            }
        });

        return result;
    }

    $("#form_deployment").on('submit', function(){

        unsaved = false;

        var form_valid = $("#form_deployment").validationEngine('validate');

        var login_valid = valid_login();

        if (!login_valid) {
            alert('Plusieurs utilisateurs ont le même login eSeller');
            return false;
        }

        var lead_societe_valid = valid_lead_societe();

        if (!lead_societe_valid) {
            alert('Un seul utilisateur par société peut recevoir les leads');
            return false;
        }

        var codesite_sites_valid = valid_codesite_sites();
        if (!codesite_sites_valid) {
            alert('Plusieurs sites ont un code site identique');
            return false;
        }

        var codesite_users_valid = valid_codesite_users();
        if (!codesite_users_valid) {
            alert('Des utilisateurs ont un code site qui ne sont pas définis');
            return false;
        }

        var name_societes_valid = valid_societes_name();
        if (!name_societes_valid) {
            alert('Plusieurs sociétés ont le même nom');
            return false;
        }

        return form_valid;
    });

    var config_chosen = {
        no_results_text: 'Pas de résultat pour',
        allow_single_deselect: true
    };

    $('.chosen-select').chosen(config_chosen)

    if ($('.chosen-select.marques').length && $('.chosen-select.marques').attr('readonly')) {
        console.log('test')
        $('.chosen-select.marques').prop('disabled', true).trigger("chosen:updated");
    }

    if (typeof title_page == 'undefined')
        title_page = 'eSeller Setup';
    $('.navbar-brand').text(title_page);

    var new_societe_id = 0;
    var new_site_id = 0;
    var new_compte_id = 0;

    $('.societe-add').on('click', function(e){
        e.preventDefault();

        var form_valid = $("#form_deployment").validationEngine('validate');
        if (!form_valid)
            return false;

        $.get('/partial/societe/' + deployment_id, function(data){

            new_societe_id = new_societe_id + 1;
            $('.societes-tab-nav').append('<li role="presentation" data-societe-id="new-'+new_societe_id+'"><a href="#societe-new-'+new_societe_id+'" aria-controls="home" role="tab" data-toggle="tab">Nouvelle société</a></li>');

            var content = $(data);
            content = "<div><div class='societe' data-societe-id=''>"+content.html()+"</div></div>";
            content = $(content)

            content.find('input,select').each(function(i,e){
                $(e).attr('name', $(e).attr('name').replace('societe-new-id', new_societe_id));
                $(e).attr('id', $(e).attr('id').replace('societe-new-id', new_societe_id));

                if (typeof $(e).attr('data-societe-id') !== typeof undefined && $(e).attr('data-societe-id') !== false) {
                    $(e).attr('data-societe-id', "new-" + new_societe_id);
                }
            });

            content.find('label').each(function(i,e){
                $(e).attr('for', $(e).attr('for').replace('societe-new-id', new_societe_id));
            });

            content.find('.societe-name').attr('data-societe-id', 'new-'+new_societe_id);

            content.find('.societe-delete').attr('data-societe-id', "new-" + new_societe_id);
            content.find('.societe').attr('data-societe-id', "new-" + new_societe_id);

            $('.societes-tab-content').append('<div role="tabpanel" class="tab-pane" id="societe-new-'+new_societe_id+'"><div class="col-sm-12">'+content.html()+'</div></div>');

            $('.societes-tab-nav').find('a').last().trigger('click');
            $('.societes-tab-content .tab-pane').last().find('select.marques').addClass('chosen-select').chosen(config_chosen);
        });

        unsaved = true;
    })

    $('form').on('focusout', 'input.site-denomination', function(){
        $(this).val($(this).val().trim());
        if ($(this).val() != "") {
            var codeSite = generateSiteCode($(this).val());
            $(this).parent().parent().find('.code-site').val(codeSite);
            updateCodeSiteToUsers();
        }
    });

    $('form').on('focusout', 'input.societe-name', function(){
        if ($(this).val() != "") {
            var societe_id = $(this).data('societe-id');
            $('.societes-tab-nav li[data-societe-id="'+societe_id+'"] a').text($(this).val());
        }
    })

    $('form').on('click', '.site-add', function(e){
        e.preventDefault();

        var form_valid = $("#form_deployment").validationEngine('validate');
        if (!form_valid)
            return false;

        var context = $(this);

        $.get('/partial/site', function(data){

            var societe_id = String($('.societes-tab-content .tab-pane.active .societe').data('societe-id'));
            var societe_edit = (societe_id.indexOf("new") == -1) ? true : false;

            new_site_id = new_site_id + 1;

            var content = $(data);

            content.find('input,select').each(function(i,e){

                var name = $(e).attr('name');
                name = name.replace('site-new-id', new_site_id);

                if (societe_edit) {
                    name = name.replace('societes[new][societe-new-id]', 'societes[edit]['+societe_id+']');
                }
                else {
                    name = name.replace('societe-new-id', societe_id.replace('new-', ''));
                }

                $(e).attr('name', name).attr('id', name);
            });

            content.find('label').each(function(i,e){

                var name = $(e).attr('for');
                name = name.replace('site-new-id', new_site_id);

                if (societe_edit) {
                    name = name.replace('societes[new][societe-new-id]', 'societes[edit]['+societe_id+']');
                }
                else {
                    name = name.replace('societe-new-id', societe_id.replace('new-', ''));
                }

                $(e).attr('for', name);
            });

            content.find('.site-delete').attr('data-site-id', 'new-' + new_site_id);

            $(context).parent().find('.site-add').before(content);
        });

        unsaved = true;
    })

    $('form').on('click', '.societe-delete', function(e){
        e.preventDefault();

        var result = confirm("Êtes-vous sûr de vouloir supprimer cette société ?");
        if (!result) {
            return;
        }

        var societe_id = String($(this).data('societe-id'));

        // edit
        if (societe_id.indexOf("new") == -1) {
            $('form').append('<input type="hidden" name="societes[delete]['+ societe_id +']" />');
        }

        $('.societes-tab-nav').find('li[data-societe-id="'+societe_id+'"]').remove();
        $('.societes-tab-content').find('#societe-'+societe_id).remove();

        $('.societes-tab-nav').find('li:first a').trigger('click');

        updateCodeSiteToUsers();
        unsaved = true;
    })

    $('form').on('click', '.societe .site-delete', function(e){
        e.preventDefault();

        var result = confirm("Êtes-vous sûr de vouloir supprimer ce point de vente ?");
        if (!result) {
            return;
        }

        var site_id = String($(this).data('site-id'));

        // edit
        if (site_id.indexOf("new") == -1) {
            $('form').append('<input type="hidden" name="sites[delete]['+ site_id +']" />');
        }

        $(this).parent().parent().parent().remove();

        updateCodeSiteToUsers();
        unsaved = true;
    })

    $('form').on('click', '.societe .compte-add', function(e){
        e.preventDefault();

        var form_valid = $("#form_deployment").validationEngine('validate');
        if (!form_valid)
            return false;

        var context = $(this);

        $.get('/partial/compte', function(data){

            var societe_id = String($('.societes-tab-content .tab-pane.active .societe').data('societe-id'));
            var societe_edit = (societe_id.indexOf("new") == -1) ? true : false;

            new_compte_id = new_compte_id + 1;

            var content = $(data);

            content.find('input,select').each(function(i,e){

                var name = $(e).attr('name');
                name = name.replace('compte-new-id', new_compte_id);

                if (societe_edit) {
                    name = name.replace('societes[new][societe-new-id]', 'societes[edit]['+societe_id+']');
                }
                else {
                    name = name.replace('societe-new-id', societe_id.replace('new-', ''));
                }

                $(e).attr('name', name).attr('id', name);
            });

            content.find('label').each(function(i,e){

                var name = $(e).attr('for');
                name = name.replace('compte-new-id', new_compte_id);

                if (societe_edit) {
                    name = name.replace('societes[new][societe-new-id]', 'societes[edit]['+societe_id+']');
                }
                else {
                    name = name.replace('societe-new-id', societe_id.replace('new-', ''));
                }

                $(e).attr('for', name);
            });

            content.find('.compte-delete').attr('data-compte-id', 'new-' + new_compte_id);

            $(context).parent().find('.compte-add').before(content);

            updateCodeSiteToUsers();
        });

        unsaved = true;
    })

    $('form').on('click', '.societe .compte-delete', function(e){
        e.preventDefault();

        var result = confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?");
        if (!result) {
            return;
        }

        var compte_id = String($(this).data('compte-id'));

        // edit
        if (compte_id.indexOf("new") == -1) {
            $('form').append('<input type="hidden" name="comptes[delete]['+ compte_id +']" />');
        }

        $(this).parent().parent().parent().remove();

        unsaved = true;
    })

    $('.no-open').collapse({
        toggle: false
    })

    if ($('form.deployment select[name="environnement"]').length) {
        if ($('form.deployment select[name="environnement"]').val()) {
            $('form.deployment select[name="environnement"]').removeAttr('disabled');
        }
    }

    $('form.deployment select[name="activite"]').on('change', function(){
        var activite_id = $(this).val()

        $('.form-group-modele_id').addClass('hide');

        if (!activite_id) {
            $('form.deployment select[name="environnement"]').attr('disabled', 'disabled').val(0);
            return;
        }

        $.get('/api/activite/get-environnements/' + activite_id, function(datas) {
            var options = '<option value=""></option>';
            $(datas).each(function(i, data){
                options = options + '<option value="'+data.id+'">'+data.name+'</option>';
            });

            $('form.deployment select[name="environnement"]').html(options);
            $('form.deployment select[name="environnement"]').removeAttr('disabled');
        });
    })

    $('form.deployment select[name="environnement"]').on('change', function(){
        var environnement_id = $(this).val()

        $.get('/api/environnement/get-modeles/' + environnement_id, function(datas) {
            var options = '<option value=""></option>';
            $(datas).each(function(i, data){
                options = options + '<option value="'+data.id+'">'+data.name+'</option>';
            });

            $('form.deployment select[name="modele_id"]').html(options);
            $('.form-group-modele_id').removeClass('hide');

            if ($('form.deployment select[name="modele_id"] option').length == 2) {
                $('form.deployment select[name="modele_id"]').val($('form.deployment select[name="modele_id"] option').last().attr('value')).trigger('change');
            }
        });
    })

    checkStep1();

    /** Possible de passer à l'étape deux ou non **/
    function checkStep1() {
        if ($('form.deployment select[name="modele_id"]').val()) {
            $('[aria-labelledby="formStepTwo"]').attr('id', 'collapseFormStepTwo').addClass('in');
        }
        else {
           $('[aria-labelledby="formStepTwo"]').attr('id', 'collapseFormStepTwo no-open').removeClass('in');
        }
    }

    /** Possible de passer à l'étape trois ou non **/
    function checkStep2() {
        var group_name = $('form.deployment input[name="group_name"]').val()
        var group_identifiant = $('form.deployment input[name="group_identifiant"]').val()
        var available = !$('form.deployment input[name="group_identifiant"]').parent().parent().hasClass('has-error');

        if (group_name && group_identifiant && check_available && available) {
            $('.submit-step2').removeAttr('disabled').removeClass('hide');
        }
        else {
            $('.submit-step2').attr('disabled', 'disabled').addClass('hide');
        }
    }

    function updateInputGroupIdentifiant(available) {
        if (!available) {
            $('form.deployment input[name="group_identifiant"]').parent().parent().addClass('has-error').find('.help-block').remove();
            $('form.deployment input[name="group_identifiant"]').parent().append('<p class="help-block">Existe déjà</p>');
        }
        else {
            $('form.deployment input[name="group_identifiant"]').parent().parent().removeClass('has-error').find('.help-block').remove()
        }
    }

    var check_available = false;

    function checkAvailableBdgp() {

        var bdgp = $('form.deployment input[name="bdgp_new"]').val();

        $.ajax({
            type: "POST",
            url: '/api/available-bdgp',
            data: {group_name: bdgp, _token: window.csrfToken},
            success: function(datas) {
                check_available = true;
                var available = Boolean(datas.available);
                updateInputGroupIdentifiant(available);
                checkStep2();
            }
        });
    }

    function updateValueGroupIdentifiant() {
        var group_name = $('form.deployment input[name="group_name"]').val()

        if (group_name) {
            $.ajax({
                type: "POST",
                url: '/api/generate-groupe-name',
                data: {group_name: group_name, _token: window.csrfToken},
                success: function(datas) {
                    $('form.deployment input[name="group_identifiant"]').val(datas);
                    $('.details_env').removeClass('hide');
                    updateGroupInfos();
                    checkAvailableBdgp();
                }
            });
        }
        else {
            $('.details_env').addClass('hide');
        }
    }

    function updateGroupInfos() {
        if ($('form.deployment input[name="group_name"]').val()) {
            var bdgp_new = $('.modele_details .bdgp_prefix').text().trim()+$('form.deployment input[name="group_identifiant"]').val().trim()+'V4';
            $('.group_infos .bdgp_new').text(bdgp_new);
            $('input[name="bdgp_new"]').val(bdgp_new);

            $.ajax({
                type: "POST",
                url: '/api/get-urls',
                data: {group_identifiant: $('form.deployment input[name="group_identifiant"]').val(), modele_id: $('form.deployment select[name="modele_id"]').val(), _token: window.csrfToken},
                success: function(datas) {
                    $('.group_infos .url_eseller').text(datas.eseller);
                    $('.group_infos .url_serveurpub').text(datas.serveurpub);
                    $('.group_infos .url_backoffice').text(datas.backoffice);
                    $('.group_infos .url_reporting').text(datas.reporting);

                }
            });

            $('.group_infos').removeClass('hide');
        }

        checkStep2();
    }

	$('form.deployment select[name="modele_id"]').on('change', function(){
        var modele_id = $(this).val()

        checkStep1();

        if (modele_id) {
            $.get('/api/modele/' + modele_id, function(datas) {
                $('.modele_details .activite_name').text(datas.activite.name)
                $('.modele_details .environnement_name').text(datas.environnement.name)
                $('.modele_details .bdgp_name').text(datas.bdgp_modele)
                $('.modele_details .bdgp_prefix').text(datas.bdgp_prefix)

                $('.modele_details').removeClass('hide');
            });
        }
        else {
            $('.modele_details').addClass('hide');
        }
    })

    var current_group_name = '';

    $('form.deployment input[name="group_name"]').focusin(function(){
        current_group_name = $(this).val();
    }).focusout(function(){
        if (current_group_name != $(this).val()) {
            updateValueGroupIdentifiant();
        }
    })

    var current_group_identifiant = '';

    $('form.deployment input[name="group_identifiant"]').focusin(function(){
        current_group_identifiant = $(this).val();
    }).focusout(function(){
        if (current_group_identifiant != $(this).val()) {
            updateGroupInfos();
            checkAvailableBdgp();
            checkStep2();
        }
    })

})

// http://stackoverflow.com/questions/11844256/alert-for-unsaved-changes-in-form
// Another way to bind the event
$(window).bind('beforeunload', function() {
    if(unsaved){
        return "Vous avez des modifications non enregistrées sur cette page. Voulez-vous quitter cette page et ne pas sauvegarder vos modifications ou rester sur cette page ?";
    }
});
// Monitor dynamic inputs
$(document).on('change', '#form_deployment :input', function(){ //triggers change in all input fields including text type
    unsaved = true;
});


// https://gist.github.com/roNn23/a86f31ecaf0c6e0a7d65
/*
 <a href="posts/2" data-method="delete"> <---- We want to send an HTTP DELETE request

 - Or, request confirmation in the process -

 <a href="posts/2" data-method="delete" data-confirm="Are you sure?">

 Add this to your view:
  <script>
		window.csrfToken = '<?php echo csrf_token(); ?>';
  </script>
  <script src="/js/deleteHandler.js"></script>
 */

$(document).ready(function(){
    var laravel = {
        initialize: function() {
            this.registerEvents();
        },

        registerEvents: function() {
            $('body').on('click', 'a[data-method]', this.handleMethod);
        },

        handleMethod: function(e) {
            var link = $(this);
            var httpMethod = link.data('method').toUpperCase();
            var form;

            // If the data-method attribute is not PUT or DELETE,
            // then we don't know what to do. Just ignore.
            if ( $.inArray(httpMethod, ['PUT', 'DELETE']) === - 1 ) {
                return;
            }

            // Allow user to optionally provide data-confirm="Are you sure?"
            if ( link.data('confirm') ) {
                if ( ! laravel.verifyConfirm(link) ) {
                    return false;
                }
            }

            form = laravel.createForm(link);
            form.submit();

            e.preventDefault();
        },

        verifyConfirm: function(link) {
            return confirm(link.data('confirm'));
        },

        createForm: function(link) {
            var form =
                $('<form>', {
                    'method': 'POST',
                    'action': link.attr('href')
                });

            var token =
                $('<input>', {
                    'name': '_token',
                    'type': 'hidden',
                    'value': window.csrfToken
                });

            var hiddenInput =
                $('<input>', {
                    'name': '_method',
                    'type': 'hidden',
                    'value': link.data('method')
                });

            return form.append(token, hiddenInput)
                .appendTo('body');
        }
    };

    laravel.initialize();

})
